<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">
          <span v-if="getPageMode == PageModes.VIEW" >{{ $t('USERADMIN.HEADER.MAINHEADER.VIEW') }}</span>
          <span v-if="getPageMode == PageModes.NEW" >{{ $t('USERADMIN.HEADER.MAINHEADER.NEW') }}</span>
          <span v-if="getPageMode == PageModes.EDIT" >{{ $t('USERADMIN.HEADER.MAINHEADER.EDIT') }}</span>
          <div v-if="getPageMode == PageModes.VIEW" class="ml-auto text-right">
            <vs-button  @click="LoadData" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span v-if="getPageMode == PageModes.VIEW" >{{ $t('USERADMIN.HEADER.MAINTEXT.VIEW') }}</span>
          <span v-if="getPageMode == PageModes.NEW" >{{ $t('USERADMIN.HEADER.MAINTEXT.NEW') }}</span>
          <span v-if="getPageMode == PageModes.EDIT" >{{ $t('USERADMIN.HEADER.MAINTEXT.EDIT') }}</span>
        </p>
        <can I="create" a="user">
          <div class="btn-alignment">
            <vs-button v-if="getPageMode == PageModes.VIEW" color="primary" type="filled" @click="AddUser" >{{ $t('USERADMIN.HEADER.BUTTON.NEWUSER') }}</vs-button>   
            <vs-button v-if="getPageMode == PageModes.VIEW" color="primary" type="filled" @click="NewPartner" >{{ $t('USERADMIN.HEADER.BUTTON.NEWPARTNER') }}</vs-button>   
                                         
          </div>
        </can>
      </vs-card>
    </vs-col>
    <vs-col v-if="getPageMode == PageModes.VIEW" type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <div id="useradmin_usertable">
        <vs-table
          search
          :data="getTableData"
          :noDataText="$t('USERADMIN.TABLE.NODATA') "
          stripe
          hoverFlat>

          <template slot="header">
            <h3>
              {{ $t('USERADMIN.TABLE.HEADER') }}
            </h3>
          </template>
          <template slot="thead">
            <vs-th >
              <!-- Image -->
            </vs-th>                
            <vs-th sort-key="firstName">
              {{ $t('USERADMIN.TABLE.COL1') }}
            </vs-th>
            <vs-th sort-key="lastName">
              {{ $t('USERADMIN.TABLE.COL2') }}
            </vs-th>
            <vs-th sort-key="email">
              {{ $t('USERADMIN.TABLE.COL3') }}
            </vs-th>
            <vs-th sort-key="partnerID">
              {{ $t('USERADMIN.TABLE.COL4') }}
            </vs-th>        
            <vs-th >
              <!-- buttons -->
            </vs-th>        
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td :data="tr.user_img">
                <vs-avatar v-bind:src="'data:image/jpeg;base64,'+tr.user_img"/>
              </vs-td>      
              <vs-td :data="tr.firstName">
                {{tr.firstName}}
              </vs-td>
              <vs-td :data="tr.lastName">
                {{tr.lastName}}
              </vs-td>

              <vs-td :data="tr.email">
                {{tr.email}}<span v-if="IsNotNull(tr.email2)"><br/>{{tr.email2}}</span>
              </vs-td>
              <vs-td :data="tr.partnerID">
                {{tr.partnerID}}
              </vs-td>

              <vs-td :data="tr.partnerID">
                <div class="btn-alignment">
                  <can I="edit" a="user">
                    <vs-button size="small" color="primary" type="border" icon="edit" @click="EditUser(tr.userGuid)"></vs-button>
                  </can>
                  <can I="block" a="user">
                    <vs-button v-if="tr.blocked == false && tr.userGuid != ownUserGuid" size="small" color="primary" type="border" icon="block" @click="BlockUser(tr.userGuid,tr.firstName+' '+tr.lastName)"></vs-button>
                    <vs-button v-if="tr.blocked == true && tr.userGuid != ownUserGuid" size="small" color="primary" type="border" icon="done" @click="UnblockUser(tr.userGuid,tr.firstName+' '+tr.lastName)"></vs-button>
                  </can>
                  <can I="delete" a="user">
                    <vs-button v-if="tr.userGuid != ownUserGuid" size="small" color="primary" type="border" icon="delete" @click="DeleteUser(tr.userGuid,tr.firstName+' '+tr.lastName)"></vs-button>
                  </can>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        </div>
        </vs-card>
      </vs-col>
  </vs-row>
  <UserData :PageMode="getPageMode" :data = null v-if="getPageMode == PageModes.EDIT || getPageMode == PageModes.NEW" />
  <AddPartnerDlg ref="addPartnerDlg"></AddPartnerDlg>
</div>
</template>

<script>

import UserData from "./components/adminpages/UserData.vue";
import {pagemode} from "../store/adminpages.module";
import helperMethods from '../helper/staticFuncHelper';
import AddPartnerDlg from "./components/dialogs/AddPartnerDlg.vue"
import codeHelper from "../helper/staticCodeHelper";

export default {
  name: "UserAdmin",
  components: {
    UserData,
    AddPartnerDlg
  },
  data: () => ({
    users: [],
    activeUserGuid: "",
    activeUserData:null,
    PageModes: null
  }),
  created () {
        this.PageModes = pagemode;
        this.$store.dispatch('adminpages/changePageModeUserAdmin', pagemode.VIEW);
        this.LoadData();
    },
  computed: 
  {
    getPageMode()
    {
        if(typeof(this.$store.state.adminpages.userAdminPageMode) != 'undefined')
        {
          return this.$store.state.adminpages.userAdminPageMode;
        }
          else
        {
          return pagemode.VIEW;
        }
    },
    getTableData() {
      
      if(this.$store.state.users.status.ready == true)
      {
        if(typeof(this.$store.state.users.useroverview) != 'undefined')
        {
          return this.$store.state.users.useroverview;
        }
          else
        {
          return null;
        }
      }
      else
      return null;
    },
    loadingTableData () {
        return this.$store.state.users.status.loading;
    },
    ownUserGuid()
    {
      return this.$store.state.users.usermin.userGuid;
    },
    loadingFullData (){
      return this.$store.state.users.userfull.status.loading;
    }
  },
  watch: {
      getTableData(value) {
          this.$data.users = value;
      },
      loadingTableData(value)
      {
        if(value)
          this.$vs.loading();
        else
          this.$vs.loading.close();
      },
      getPageMode(value)
      {
        return value;
      },
      loadingFullData(value)
      {
        if(value)
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#useradmin_usertable'});
        else
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#useradmin_usertable'});
      }
    },
  methods: {
    ...helperMethods,
    ...codeHelper,
      LoadData: function ()
      {
        const { dispatch } = this.$store;
        dispatch('users/getUserOverviewData', { root: true },{ root: true });
      },
      NewPartner(){
        this.$refs.addPartnerDlg.ShowDlg();
      },
      AddUser: function (){
        this.$store.dispatch('adminpages/changePageModeUserAdmin', pagemode.NEW );
      },
      EditUser: function (guid)
      {
        this.activeUserGuid = guid;
        this.$store.dispatch('timerec/getTimeModels', { root: true },{ root: true }); 
        this.$store.dispatch('timerec/getTimeRecsStd', guid); 
        this.$store.dispatch('users/getUserFullData', guid)
        .then(response => {
          if(response != null)
          {
            this.$store.dispatch('adminpages/changePageModeUserAdmin', pagemode.EDIT);
          }
        }, 
        error =>{
          this.$store.dispatch('adminpages/changePageModeUserAdmin', pagemode.VIEW);
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('ERROR.TITEL'),'text':this.$t('USERADMIN.ERROR.NOUSERDATA')+error});   
        });
      },
      BlockUser: function (userid,username)
      {
        this.openConfirmBlockUser(userid,username);
      },
      DeleteUser: function (userid,username)
      {
        this.openConfirmDeleteUser(userid,username);
      },
      UnblockUser: function (userguid,username)
      {
        let payload = {'guid': userguid,'name': username};
        this.$store.dispatch('users/unblockUser', payload)
        .then(response => {
          if(response.requestOk === true)
          {
            this.$store.dispatch('users/getUserOverviewDataSingle', response.guid);
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('USERADMIN.SUCCESS.UNBLOCK').replace("*1*",response.name)});   
          }
        }, 
        error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.ERROR.UNBLOCK').replace("*1*",error)}); 
        });
      },
      sendBlockRequest: function(parameters)
      {
        this.$store.dispatch('users/blockUser', parameters)
        .then(response => {
          if(response.requestOk === true)
          {
            this.$store.dispatch('users/getUserOverviewDataSingle', response.guid);
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('USERADMIN.SUCCESS.BLOCK').replace("*1*",response.name)});   
          }
        }, 
        error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.ERROR.BLOCK').replace("*1*",error)});                  
        });
      },
      sendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('users/deleteUser', parameters)
        .then(response => {
          if(response.requestOk === true)
          {
            this.LoadData();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('USERADMIN.SUCCESS.DELETE').replace("*1*",response.name)});  
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.SUCCESS.DELETE').replace("*1*",error)});  
        });
      },      
      openConfirmBlockUser(userguid,username) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.BLOCK'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('USERADMIN.QUESTION.BLOCK').replace("*1*",username),
          accept: this.sendBlockRequest,
          parameters:{"guid": userguid,"name": username}
        });
      
      },
      openConfirmDeleteUser(userguid,username) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('USERADMIN.QUESTION.DELETE').replace("*1*",username),
          accept: this.sendDeleteRequest,
          parameters:{"guid": userguid,"name": username}
        });
      
      },

      goToNext(parameters)
      {
        this.$store.dispatch('unsavedData/setUserAdmin', false);
        this.$router.push(parameters.to);
      }
  },
  beforeRouteLeave (to, from, next)
  {
    if(this.$store.state.unsavedData.userAdmin)
    {
      var toPage = to.name;      
      // Hinweis dass noch nicht gespeichert wurde
      this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.LEAVE.TITEL'),
          acceptText: this.$t('COM.YES'),
          cancelText:this.$t('COM.NO'),
          text: this.$t('COM.REALLY_LEAVE'),
          accept:this.goToNext,
          parameters:{"to": toPage}
        });

        next(false);
    }
    else
    {
      // keine ungespeicherten Daten vorhanden
      this.$store.dispatch('adminpages/changePageModeUserAdmin', pagemode.VIEW);
      next();
    }

  }
};

</script>