<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddTimeRec"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('TIMEREC.ADD_TIMEREC.HEADER.TEXT')}}</p>

          <div class="default-input clearfix align-items-center mb-3">
            <DatePicker v-if="!isStd" Type="datetime" class="float-left" :Label="$t('TIMEREC.START')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('TIMEREC.TITLE.START')" v-model="data.start"></DatePicker>
            <DatePicker v-if="isStd" Type="time" class="float-left" :Label="$t('TIMEREC.START')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('TIMEREC.TITLE.START')" v-model="data.start"></DatePicker>
            <DatePicker Type="time" class="float-left" :Label="$t('TIMEREC.END')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('TIMEREC.TITLE.END')" v-model="data.end"></DatePicker>
            <vs-select :label="$t('TIMEREC.TYPE')" v-model="data.type" class="float-left mr-2" >
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in timeRecTypes" />
            </vs-select>
          </div>
        <div>
          <vs-textarea height="200px" counter="500" :label="$t('TIMEREC.DESCR')" :counter-danger.sync="description_fail" v-model="data.description" />
        </div>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import DatePicker from '../DatePicker.vue';
import {timeRecType} from '../../../helper/enumHelper';

export default {
  name: "AddTimeRecDlg",
  props:[],
  components:{
    DatePicker
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('TIMEREC.ADD_TIMEREC.TITLE'),
      end_fail:false,
      end_ok:false,
      description_fail:false,
      description_ok:false,
      data:this.GetEmptyData(),
      isStd:false
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(std){
        this.ClearForm();
        this.isStd = std;
        this.dlgActive=true;
        this.popupTitle = this.$t('TIMEREC.ADD_TIMEREC.TITLE');
        
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data,std){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();

          this.popupTitle = this.$t('TIMEREC.ADD_TIMEREC.TITLE.EDIT');
          
          this.data = JSON.parse(JSON.stringify(data));
          this.isStd = std;
          this.dlgActive=true;
        }
      },
      accepted(){

          var url = "timerec/saveTimeRec";
          if(this.isStd)
          {
            url = "timerec/saveTimeRecStd";
            if(!this.IsNotNull(this.data.userguid) || this.data.userguid.length == 0)
            {
              this.data.userguid = this.$store.state.users.userfull.userdata.userGuid;
            }
          }

          this.$store.dispatch(url, this.data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TIMEREC.ADD_TIMEREC.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('TIMEREC.ADD_TIMEREC.WARNING.SAVE').replace("*1*",text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TIMEREC.ADD_TIMEREC.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
      },
      GetEmptyData(){
        return {
          id:0,
          userguid:"",
          description:"",
          year:0,
          start:null,
          end:null,
          type:0
        };
      }
  },
  computed:{
    formValid(){
      return this.end_ok && (this.description_ok || this.IsDescriptionMustHave == false);
    },
    end(){
      return this.data.end;
    },
    description(){
      return this.data.description;
    },
    timeRecTypes(){
      return this.GetTimeRecTypeForSelect(); 
    },
    start(){
      return this.data.start;
    },
    IsDescriptionMustHave(){
      return this.data.type == timeRecType.OTHERS || this.data.type == timeRecType.SPECIAL || (this.data.type == timeRecType.TIMEREC && this.isStd == false);
    }
  },
  watch:{
    end(value)
    {
       this.end_fail = value <= this.data.start;
       this.end_ok = !this.end_fail;
    },
    description(value)
    {
      this.description_fail = value.length == 0;
      this.description_ok = !this.description_fail;
    },
    start(value){
      this.data.end = value;
    } 
  }
};
</script>
<style>
div#promptAddTimeRec > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddTimeRec > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>